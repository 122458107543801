import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { usePostApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  document?: string;
};

type FormTypes = {
  name: string;
  description: string;
};

const schemaAddDocumentForm = Joi.object({
  description: Joi.optional(),
  name: Joi.optional(),
}).unknown(true);

export const NewDocumentModal: FC<Props> = ({ open, onClose, document }) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      setFile(file);
      setFileName(file.name);

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {
    postFormData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.document}`);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: joiResolver(schemaAddDocumentForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = useCallback(async (data: FormTypes) => {
    if(document) {
      const blob = new Blob([document], { type: "text/plain;charset=" + "UTF-8" });

      var reader = new FileReader();
    reader.onload = async (evt) => {
      const newFile = new File(
        [evt?.target?.result as any],
        `${data?.name}`,
        {
          type: "text/html",
        }
      );

      const response = await postFormData({
        ...data,
        workspace_id: user?.workspace_id,
        type: "review_agreement",
        file: newFile,
      });
      if (response) {
        reset();
        showSuccess(t('Document added successfully'));
        onClose();
      }
    };
    reader.readAsArrayBuffer(blob);
  
      
    } else {
      showError(t('Document is empty'));
    }

  }, [document, postFormData]);

  if (isLoadingPost) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingPost}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('Save Document')}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="name"
                    fullWidth
                    label={t('Document Name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    size="small"
                    id="description"
                    fullWidth
                    label={t('Document Description')}
                    {...field}
                  />
                </div>
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPost}
              >
                {t('Save Document')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
